import { type FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { SbSharedWordpressArticlesWrapper } from '../SbSharedWordpressArticles/SbSharedWordpressArticlesWrapper';
import { SbRespInteriorsModule } from './SbRespInteriorsModule';
import { showInteriorsModule } from '../SbSharedInteriorsModule/interiorsModuleHelpers';

import { type SbRespInteriorsWordpressArticlesWrapper_itemSearch$data } from './__generated__/SbRespInteriorsWordpressArticlesWrapper_itemSearch.graphql';
import { type SbRespInteriorsWordpressArticlesWrapper_viewer$data } from './__generated__/SbRespInteriorsWordpressArticlesWrapper_viewer.graphql';
import { type SbRespInteriorsWordpressArticlesWrapper_user$data } from './__generated__/SbRespInteriorsWordpressArticlesWrapper_user.graphql';

type Props = {
    itemSearch: SbRespInteriorsWordpressArticlesWrapper_itemSearch$data;
    viewer: SbRespInteriorsWordpressArticlesWrapper_viewer$data;
    user: SbRespInteriorsWordpressArticlesWrapper_user$data;
    isClient: boolean;
};

const SbRespInteriorsWordpressArticlesWrapperComponent: FC<Props> = props => {
    const { itemSearch, viewer, user, isClient } = props;
    const editorial = itemSearch.editorial;

    if (showInteriorsModule(itemSearch.interiorPhotos?.photos)) {
        return (
            <SbRespInteriorsModule
                editorial={editorial}
                itemSearch={itemSearch}
                user={user}
                isClient={isClient}
            />
        );
    } else {
        return <SbSharedWordpressArticlesWrapper editorial={editorial} viewer={viewer} />;
    }
};

export const SbRespInteriorsWordpressArticlesWrapper = createFragmentContainer(
    SbRespInteriorsWordpressArticlesWrapperComponent,
    {
        viewer: graphql`
            fragment SbRespInteriorsWordpressArticlesWrapper_viewer on Viewer {
                ...SbSharedWordpressArticlesWrapper_viewer
            }
        `,
        itemSearch: graphql`
            fragment SbRespInteriorsWordpressArticlesWrapper_itemSearch on ItemSearchQueryConnection {
                ...SbRespInteriorsModule_itemSearch
                editorial: editorialArticles(first: 8) {
                    ...SbRespInteriorsModule_editorial
                    ...SbSharedWordpressArticlesWrapper_editorial
                }
                interiorPhotos(template: "tradeProjectPhoto.search", first: $interiorPhotosCount) {
                    photos {
                        serviceId
                    }
                }
            }
        `,
        user: graphql`
            fragment SbRespInteriorsWordpressArticlesWrapper_user on User {
                ...SbRespInteriorsModule_user
            }
        `,
    }
);
