/**
 * @generated SignedSource<<b6f666db0c4884d79c247cca9a5f0106>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedL1ModuleBanner_l1Module$data = {
  readonly banners: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"SbSharedL1ModuleTile_l1ModuleItemTile">;
  } | null> | null;
  readonly " $fragmentType": "SbSharedL1ModuleBanner_l1Module";
};
export type SbSharedL1ModuleBanner_l1Module$key = {
  readonly " $data"?: SbSharedL1ModuleBanner_l1Module$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedL1ModuleBanner_l1Module">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedL1ModuleBanner_l1Module",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "L1ModuleItemTile",
      "kind": "LinkedField",
      "name": "banners",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbSharedL1ModuleTile_l1ModuleItemTile"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "L1Module",
  "abstractKey": null
};

(node as any).hash = "6c4ec08a7d5aeff9acbe1796bc078cb1";

export default node;
