/**
 * @generated SignedSource<<63d7b9d51a28ac95cd2a20d12cc9528d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedInteriorsArticles_editorial$data = {
  readonly articles: ReadonlyArray<{
    readonly imageUrl: {
      readonly full: string;
      readonly medium: string;
      readonly thumb: string;
    };
    readonly link: string;
    readonly publishedDate: string;
    readonly source: string;
    readonly subtitle: string;
    readonly title: string;
  }> | null;
  readonly " $fragmentType": "SbSharedInteriorsArticles_editorial";
};
export type SbSharedInteriorsArticles_editorial$key = {
  readonly " $data"?: SbSharedInteriorsArticles_editorial$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedInteriorsArticles_editorial">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedInteriorsArticles_editorial",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WordpressArticleType",
      "kind": "LinkedField",
      "name": "articles",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publishedDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WordpressArticleImageType",
          "kind": "LinkedField",
          "name": "imageUrl",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thumb",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "medium",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "full",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subtitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "link",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "source",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WordpressArticlesType",
  "abstractKey": null
};

(node as any).hash = "6cfe8c9419e184b53659c328e41d47d5";

export default node;
