/**
 * @generated SignedSource<<2eb1d77b4f316d25efbb3e1da7c9887c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedForceSearchLink_itemSearch$data = {
  readonly pageType: string | null;
  readonly searchCorrections: {
    readonly classificationSearchTerm: string | null;
    readonly forceSearchUri: string | null;
    readonly originalSearchTerm: string | null;
    readonly semanticSearchTerm: string | null;
  } | null;
  readonly searchTerm: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedSearchSuggestions_itemSearch">;
  readonly " $fragmentType": "SbSharedForceSearchLink_itemSearch";
};
export type SbSharedForceSearchLink_itemSearch$key = {
  readonly " $data"?: SbSharedForceSearchLink_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedForceSearchLink_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedForceSearchLink_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pageType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "searchTerm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchCorrectionsType",
      "kind": "LinkedField",
      "name": "searchCorrections",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forceSearchUri",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "originalSearchTerm",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "semanticSearchTerm",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "classificationSearchTerm",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedSearchSuggestions_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "0a1091f51c79afafcd5ded9fc1f26c4e";

export default node;
