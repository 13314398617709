import { type FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';
import { pageTypeConstants } from '../../constants/pageTypeConstants';

import styles from './SbSharedInteriorsHeader.scss';

import { type SbSharedInteriorsHeader_itemSearch$data } from './__generated__/SbSharedInteriorsHeader_itemSearch.graphql';

type Props = {
    itemSearch: SbSharedInteriorsHeader_itemSearch$data;
};

export const SbSharedInteriorsHeaderComponent: FC<Props> = ({ itemSearch }) => {
    const pageType = itemSearch?.pageType;
    const attributeDisplayName = itemSearch.meta?.attributeDisplayName || '';
    return (
        <>
            <HeadingLevel>
                {Heading => (
                    <Heading className={styles.sectionHeader}>
                        {pageType === pageTypeConstants.CREATOR ? (
                            <FormattedMessage
                                id="abf.SbRespInteriorsHeader.creatorHeader"
                                defaultMessage="Interiors Featuring {name}"
                                values={{
                                    name: attributeDisplayName,
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                id="abf.SbRespInteriorsHeader.header"
                                defaultMessage="{name} Interiors"
                                values={{
                                    name: attributeDisplayName,
                                }}
                            />
                        )}
                    </Heading>
                )}
            </HeadingLevel>
            <div className={styles.sectionSubheader}>
                <FormattedMessage
                    defaultMessage="Explore the work of the world's best interior designers"
                    id="abf.SbSharedInteriorsHeader.subheader"
                />
            </div>
        </>
    );
};

export const SbSharedInteriorsHeader = createFragmentContainer(SbSharedInteriorsHeaderComponent, {
    itemSearch: graphql`
        fragment SbSharedInteriorsHeader_itemSearch on ItemSearchQueryConnection {
            pageType
            meta {
                attributeDisplayName
            }
        }
    `,
});
