import { type FC } from 'react';
import { Badge } from 'dibs-elements/exports/Badge';
import { Link } from 'dibs-elements/exports/Link';
import { trackDesignFeature } from 'dibs-interiors-photos/exports/trackingHelpers';

import styles from './PhotoRelatedSearchLinksContainer.scss';

type LinkType = {
    text: string | null;
    path: string | null;
};

type Props = {
    isPillBackgroundWhite?: boolean;
    trackingLabel: 'browse' | 'project';
    links?: readonly (LinkType | null)[] | null;
};

const PhotoRelatedSearchLinksContainer: FC<Props> = props => {
    const { links, isPillBackgroundWhite, trackingLabel } = props;
    return (
        <>
            {(links || []).map(link => {
                const { text, path } = link || {};
                return (
                    <Link
                        key={path}
                        className={styles.link}
                        href={path || ''}
                        onClick={() =>
                            trackDesignFeature({
                                action: 'related photo click',
                                label: trackingLabel,
                            })
                        }
                        buyerLinkType="linkNoDecoration"
                    >
                        <Badge
                            type="pill"
                            size="medium-regular"
                            interactive
                            backgroundColor={isPillBackgroundWhite ? 'white' : undefined}
                        >
                            {text}
                        </Badge>
                    </Link>
                );
            })}
        </>
    );
};

export default PhotoRelatedSearchLinksContainer;
