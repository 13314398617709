/**
 * @generated SignedSource<<b592412efe28d8b4e7e2169c3ea502c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedInteriorsBrowseMore_interiorPhotos$data = {
  readonly photosBrowseLink: string | null;
  readonly searchBrowseBackLink: {
    readonly displayName: string | null;
    readonly linkUrl: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"interiorsModuleHelpers_interiorPhotos">;
  readonly " $fragmentType": "SbSharedInteriorsBrowseMore_interiorPhotos";
};
export type SbSharedInteriorsBrowseMore_interiorPhotos$key = {
  readonly " $data"?: SbSharedInteriorsBrowseMore_interiorPhotos$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedInteriorsBrowseMore_interiorPhotos">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedInteriorsBrowseMore_interiorPhotos",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "interiorsModuleHelpers_interiorPhotos",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InteriorPhotosModulePhotoSearchParam",
          "kind": "LinkedField",
          "name": "photoSearchParam",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photosBrowseLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InteriorPhotosModuleSearchBrowseBackLink",
      "kind": "LinkedField",
      "name": "searchBrowseBackLink",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SearchBrowseInteriorPhotosModule",
  "abstractKey": null
};

(node as any).hash = "e35314a02b959440527b2bc5e2435292";

export default node;
