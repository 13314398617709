import { type FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';
import { FormattedMessage } from 'dibs-react-intl';
import { useCurrency } from 'dibs-buyer-layout/exports/useCurrency';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';

// components
import { SbSharedPriceTile } from '../SbSharedPriceTile/SbSharedPriceTile';
import { SbRespTilesSwiper } from '../SbRespTilesSwiper/SbRespTilesSwiper';
import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';
import { SbRespTradeFreeShippingPromoTile } from '../SbRespTradeFreeShippingPromoTile/SbRespTradeFreeShippingPromoTile';

import dibsCss from 'dibs-css';

import { type SbRespPriceTiles_viewer$key } from './__generated__/SbRespPriceTiles_viewer.graphql';
import { type SbRespPriceTiles_itemSearch$key } from './__generated__/SbRespPriceTiles_itemSearch.graphql';

const MAX_DISPLAY_PRICE_TILES = 4;

const getPriceTilesForCurrency = <T extends { currency: string | null }>(
    tiles: T[],
    currency: string
): T[] => tiles.filter(tile => tile?.currency === currency);

const viewerFragment = graphql`
    fragment SbRespPriceTiles_viewer on Viewer {
        shippingFilters(uriRef: $uriRef, zipCode: $userZipCode, countryCode: $userCountryCode)
            @include(if: $isClient) {
            name
            values {
                linkReference
            }
        }
        ...SbRespTradeFreeShippingPromoTile_viewer
    }
`;
const itemSearchFragment = graphql`
    fragment SbRespPriceTiles_itemSearch on ItemSearchQueryConnection {
        ...SbSharedPriceTile_itemSearch
        ...SbRespTradeFreeShippingPromoTile_itemSearch
        displayUriRef
        priceTiles(isTrade: $isTrade) {
            currency
            displayName
            image
            url
        }
    }
`;

type Props = {
    viewer: SbRespPriceTiles_viewer$key;
    itemSearch: SbRespPriceTiles_itemSearch$key;
};

export const SbRespPriceTiles: FC<Props> = ({ viewer: viewerRef, itemSearch: itemSearchRef }) => {
    const { isTablet } = useServerVarsContext();
    const { currency } = useCurrency();
    const viewer = useFragment(viewerFragment, viewerRef);
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const displayUriRef = itemSearch?.displayUriRef || '/';
    const priceTiles = (itemSearch?.priceTiles || []).filter(filterFalsy);

    if (!priceTiles.length) {
        return null;
    }
    const tiles = getPriceTilesForCurrency(priceTiles, currency)
        .filter(priceTile => priceTile.displayName && priceTile.image && priceTile.url)
        .slice(0, MAX_DISPLAY_PRICE_TILES)
        .map(tile => ({
            displayName: tile.displayName || '',
            image: tile.image || '',
            url: tile.url || displayUriRef,
        }));

    return (
        <div className={classnames(dibsCss.flex, dibsCss.justifyBetween, dibsCss.mbLarge)}>
            <SbRespTilesSwiper displayUriRef={displayUriRef} isTablet={isTablet}>
                {[
                    ...tiles.map((priceTile, i) => {
                        const { displayName, image, url } = priceTile;
                        const indexLabel = i + 1;
                        return (
                            <SbSharedPriceTile
                                displayName={displayName}
                                hoverMessage={
                                    <FormattedMessage
                                        id="sb.SbRespPriceTiles.shop"
                                        defaultMessage="shop"
                                    />
                                }
                                image={image}
                                isPriceBucket
                                key={`priceTile-${displayName}`}
                                position={indexLabel}
                                promoLabel={`Trade Price Range ${indexLabel}`}
                                itemSearch={itemSearch}
                                url={url}
                            />
                        );
                    }),
                    <SbRespTradeFreeShippingPromoTile
                        key="trade-free-shipping-tile"
                        currency={currency}
                        position={5}
                        itemSearch={itemSearch}
                        viewer={viewer}
                    />,
                ]}
            </SbRespTilesSwiper>
        </div>
    );
};
