import { Link } from 'dibs-elements/exports/Link';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateUriRef as updateUriRefAction } from '../../actions/filterActions';

//eslint-disable-next-line no-shadow
export const SbSharedLinkComponent = ({
    children,
    href,
    onClick = () => {},
    dataTn = '',
    updateUriRef = () => {},
    className,
}) => (
    <Link
        href={href}
        onClick={e => {
            e.preventDefault();
            updateUriRef({ uriRef: href });
            onClick(e, href);
        }}
        dataTn={dataTn}
        className={className}
    >
        {children}
    </Link>
);

SbSharedLinkComponent.propTypes = {
    href: PropTypes.string.isRequired,
    updateUriRef: PropTypes.func,
    onClick: PropTypes.func,
    children: PropTypes.node,
    dataTn: PropTypes.string,
    className: PropTypes.string,
};

export const SbSharedLink = connect(null, { updateUriRef: updateUriRefAction })(
    SbSharedLinkComponent
);
