import { type FC, type ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay';

// components
import { FormattedMessage } from 'dibs-react-intl';
import { hasKey } from 'dibs-ts-utils/src/hasKey';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';
import { useCurrency } from 'dibs-buyer-layout/exports/useCurrency';
import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';
import { SbSharedFeaturedDeal } from '../SbSharedFeaturedDeal/SbSharedFeaturedDeal';
import { SbSharedL1ModuleTile } from '../SbSharedL1ModuleTile/SbSharedL1ModuleTile';
import { SbSharedL1ModuleBanner } from '../SbSharedL1ModuleBanner/SbSharedL1ModuleBanner';
import { SbRespFeaturedTrendingLazy } from '../SbRespFeaturedTrending/SbRespFeaturedTrendingLazy';
import { shopVertical } from '../sbMessages';

// types
import { type SbRespL1Landing_itemSearch$key as ItemSearch } from './__generated__/SbRespL1Landing_itemSearch.graphql';

//styles
import styles from './SbRespL1Landing.scss';

type Props = {
    itemSearch: ItemSearch;
};

const itemSearchFragment = graphql`
    fragment SbRespL1Landing_itemSearch on ItemSearchQueryConnection {
        ...SbSharedFeaturedDeal_itemSearch
        topCategoryL1
        meta {
            header
            subHeader
        }
        l1Module(previewId: $previewId) {
            ...SbSharedL1ModuleBanner_l1Module
            secondTile {
                ...SbSharedL1ModuleTile_l1ModuleItemTile
            }
        }
    }
`;

export const SbRespL1Landing: FC<Props> = ({ itemSearch: itemSearchRef }) => {
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const { isNavigationVariant } = useServerVarsContext();
    const { currency } = useCurrency();

    const { meta, topCategoryL1, l1Module } = itemSearch || {};
    const { header, subHeader } = meta || {};

    if (!topCategoryL1) {
        return null;
    }

    function getHeading(): ReactNode {
        if (hasKey(shopVertical, topCategoryL1)) {
            // in TOF-1864 AB test we want to replace headers from "Jewelry and Watches" to "Jewelry"
            if (topCategoryL1 === 'jewelry' && isNavigationVariant) {
                return <FormattedMessage id="sb.shopJewelry.test" defaultMessage="Shop Jewelry" />;
            } else {
                return shopVertical[topCategoryL1];
            }
        }

        return null;
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.headerWrapper}>
                    <h1 className={styles.header} data-tn="header-title">
                        {header}
                    </h1>
                    <HeadingLevel>
                        {Heading => (
                            <Heading className={styles.subHeader} dataTn="header-sub-title">
                                {subHeader}
                            </Heading>
                        )}
                    </HeadingLevel>
                </div>
                {l1Module && (
                    <div className={styles.topModule}>
                        <SbSharedFeaturedDeal currency={currency} itemSearch={itemSearch} />
                        <SbSharedL1ModuleBanner l1Module={l1Module} />
                        <SbSharedL1ModuleTile
                            l1ModuleItemTile={l1Module?.secondTile || null}
                            creative="Right Tile"
                        />
                    </div>
                )}
            </div>

            <div className={styles.lowerModuleContainer}>
                <div className={styles.lowerModule}>
                    <SbRespFeaturedTrendingLazy vertical={topCategoryL1} />
                </div>
            </div>

            <HeadingLevel>
                {Heading => <Heading className={styles.shop}>{getHeading()}</Heading>}
            </HeadingLevel>
        </>
    );
};
