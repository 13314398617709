/**
 * @generated SignedSource<<6ccbd6224bd4fe0e15b5962909afe2be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedWordpressArticlesWrapper_editorial$data = {
  readonly articles: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"EditorialModuleArticles_articles">;
  }> | null;
  readonly totalResults: number | null;
  readonly " $fragmentType": "SbSharedWordpressArticlesWrapper_editorial";
};
export type SbSharedWordpressArticlesWrapper_editorial$key = {
  readonly " $data"?: SbSharedWordpressArticlesWrapper_editorial$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedWordpressArticlesWrapper_editorial">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedWordpressArticlesWrapper_editorial",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WordpressArticleType",
      "kind": "LinkedField",
      "name": "articles",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EditorialModuleArticles_articles"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalResults",
      "storageKey": null
    }
  ],
  "type": "WordpressArticlesType",
  "abstractKey": null
};

(node as any).hash = "b0b5997b84145ef8d625a0f5c5ce1107";

export default node;
