/**
 * @generated SignedSource<<247bec4c160b9881e13d750c1927e61b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespPriceTiles_viewer$data = {
  readonly shippingFilters?: ReadonlyArray<{
    readonly name: string | null;
    readonly values: ReadonlyArray<{
      readonly linkReference: string | null;
    } | null> | null;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespTradeFreeShippingPromoTile_viewer">;
  readonly " $fragmentType": "SbRespPriceTiles_viewer";
};
export type SbRespPriceTiles_viewer$key = {
  readonly " $data"?: SbRespPriceTiles_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespPriceTiles_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isClient"
    },
    {
      "kind": "RootArgument",
      "name": "uriRef"
    },
    {
      "kind": "RootArgument",
      "name": "userCountryCode"
    },
    {
      "kind": "RootArgument",
      "name": "userZipCode"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespPriceTiles_viewer",
  "selections": [
    {
      "condition": "isClient",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "countryCode",
              "variableName": "userCountryCode"
            },
            {
              "kind": "Variable",
              "name": "uriRef",
              "variableName": "uriRef"
            },
            {
              "kind": "Variable",
              "name": "zipCode",
              "variableName": "userZipCode"
            }
          ],
          "concreteType": "ShippingFilterType",
          "kind": "LinkedField",
          "name": "shippingFilters",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FilterValueType",
              "kind": "LinkedField",
              "name": "values",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "linkReference",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespTradeFreeShippingPromoTile_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "d94854ee2a07c242f57bfe99f0724a0b";

export default node;
