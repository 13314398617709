/**
 * @generated SignedSource<<6a58964559bfe54e3795a2f465d5c73e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespInteriorsModule_editorial$data = {
  readonly totalResults: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedInteriorsArticles_editorial">;
  readonly " $fragmentType": "SbRespInteriorsModule_editorial";
};
export type SbRespInteriorsModule_editorial$key = {
  readonly " $data"?: SbRespInteriorsModule_editorial$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespInteriorsModule_editorial">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespInteriorsModule_editorial",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedInteriorsArticles_editorial"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalResults",
      "storageKey": null
    }
  ],
  "type": "WordpressArticlesType",
  "abstractKey": null
};

(node as any).hash = "138399f457f44aba9e6abd53abd5150d";

export default node;
