/**
 * @generated SignedSource<<e3439ffd6e2924499fa8f5aed620bc7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespInteriorsWordpressArticlesWrapper_itemSearch$data = {
  readonly editorial: {
    readonly " $fragmentSpreads": FragmentRefs<"SbRespInteriorsModule_editorial" | "SbSharedWordpressArticlesWrapper_editorial">;
  } | null;
  readonly interiorPhotos: {
    readonly photos: ReadonlyArray<{
      readonly serviceId: string | null;
    } | null> | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespInteriorsModule_itemSearch">;
  readonly " $fragmentType": "SbRespInteriorsWordpressArticlesWrapper_itemSearch";
};
export type SbRespInteriorsWordpressArticlesWrapper_itemSearch$key = {
  readonly " $data"?: SbRespInteriorsWordpressArticlesWrapper_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespInteriorsWordpressArticlesWrapper_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "interiorPhotosCount"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespInteriorsWordpressArticlesWrapper_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespInteriorsModule_itemSearch"
    },
    {
      "alias": "editorial",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 8
        }
      ],
      "concreteType": "WordpressArticlesType",
      "kind": "LinkedField",
      "name": "editorialArticles",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbRespInteriorsModule_editorial"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbSharedWordpressArticlesWrapper_editorial"
        }
      ],
      "storageKey": "editorialArticles(first:8)"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "interiorPhotosCount"
        },
        {
          "kind": "Literal",
          "name": "template",
          "value": "tradeProjectPhoto.search"
        }
      ],
      "concreteType": "SearchBrowseInteriorPhotosModule",
      "kind": "LinkedField",
      "name": "interiorPhotos",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PhotosSearchBrowsePhoto",
          "kind": "LinkedField",
          "name": "photos",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "serviceId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "9148a984a20808eddf3198f41fe886b7";

export default node;
