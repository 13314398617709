import { type FC, useState, type ReactNode } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { Carousel } from 'dibs-carousel';
import { SbSharedInteriorsHeader } from '../SbSharedInteriorsModule/SbSharedInteriorsHeader';
import { usePhotosLikeDataRefetch } from 'dibs-interiors-photos/exports/usePhotosLikeDataRefetch';
import classnames from 'classnames';

import { SbRespInteriorsLazyLoadImage } from './SbRespInteriorsLazyLoadImage';
import SbSharedInteriorsHeartWrapper from '../SbSharedInteriorsModule/SbSharedInteriorsHeartWrapper/SbSharedInteriorsHeartWrapper';
import SbSharedInteriorsPhotoRelatedSearchLinks from '../SbSharedInteriorsModule/SbSharedInteriorsPhotoRelatedSearchLinks';
import { SbSharedInteriorsBrowseMore } from '../SbSharedInteriorsModule/SbSharedInteriorsBrowseMore';

import { type SbRespInteriorsPhotoCarousel_interiorPhotos$data } from './__generated__/SbRespInteriorsPhotoCarousel_interiorPhotos.graphql';
import { type SbRespInteriorsPhotoCarousel_photos$data } from './__generated__/SbRespInteriorsPhotoCarousel_photos.graphql';
import { type SbRespInteriorsPhotoCarousel_itemSearch$data } from './__generated__/SbRespInteriorsPhotoCarousel_itemSearch.graphql';
import { type SbRespInteriorsPhotoCarousel_user$data } from './__generated__/SbRespInteriorsPhotoCarousel_user.graphql';

import styles from './SbRespInteriorsPhotoCarousel.scss';

const ITEMS_PER_PAGE = 4;

type ImageLinkProps = {
    index: number;
    onClick: () => void;
    children: ReactNode;
};

const ImageLink: FC<ImageLinkProps> = ({ children, index, onClick }) => (
    <div
        tabIndex={0}
        role="link"
        className={styles.imgLink}
        data-tn={`interior-photo-${index}`}
        onClick={onClick}
        onKeyPress={onClick}
    >
        {children}
    </div>
);

type Props = {
    interiorPhotos: SbRespInteriorsPhotoCarousel_interiorPhotos$data | null | undefined;
    photos: SbRespInteriorsPhotoCarousel_photos$data;
    itemSearch: SbRespInteriorsPhotoCarousel_itemSearch$data;
    onPhotoIndexChange: (newIndex: number) => void;
    onViewInteriorClick: () => void;
    onPhotoClick: () => void;
    isClient: boolean;
    onPhotoLikeAdded: () => void;
    user: SbRespInteriorsPhotoCarousel_user$data;
    isModuleVisible: boolean;
};

const SbRespInteriorsPhotoCarouselComponent: FC<Props> = ({
    interiorPhotos,
    photos,
    itemSearch,
    onPhotoIndexChange,
    onViewInteriorClick,
    onPhotoClick,
    isClient,
    onPhotoLikeAdded,
    user,
    isModuleVisible,
}) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const photoCount = photos.length;
    const hasMorePhotos = (interiorPhotos?.totalResults || 0) > photoCount;
    /*
        This represents the number of iterations the carousel makes to display the photos.
        If hasMorePhotos is true, then we add +1 to the iterations to display the browse more tile.
    */
    const totalItems = hasMorePhotos ? photoCount + 1 : photoCount;
    const { photoLikeDataMap, isRefetching } = usePhotosLikeDataRefetch({
        photosRef: photos,
        shouldFetch: isModuleVisible,
        userId: user?.serviceId || '',
    });

    const onPhotoClickCallback = (index: number): void => {
        onPhotoIndexChange(index);
        onPhotoClick();
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.headerWrapper}>
                <SbSharedInteriorsHeader itemSearch={itemSearch} />
            </div>
            <div className={styles.imgWrapper}>
                {photoCount < ITEMS_PER_PAGE ? (
                    <div className={styles.centeredImgWrapper}>
                        {photos.map((photo, index) => {
                            const { photoLikeId } =
                                photoLikeDataMap.get(photo?.serviceId || '') || {};
                            return (
                                <ImageLink
                                    key={photo.path || index}
                                    index={index}
                                    onClick={() => onPhotoClickCallback(index)}
                                >
                                    <SbRespInteriorsLazyLoadImage
                                        imgClass={styles.img}
                                        lazyLoadClass={styles.lazyLoad}
                                        photo={photo}
                                    />
                                    {isClient && !isRefetching && (
                                        <div className={styles.heartWrapper}>
                                            <SbSharedInteriorsHeartWrapper
                                                onPhotoLikeAdded={onPhotoLikeAdded}
                                                photoLikeId={photoLikeId}
                                                photo={photo}
                                            />
                                        </div>
                                    )}
                                </ImageLink>
                            );
                        })}
                    </div>
                ) : (
                    <Carousel
                        classNames={{
                            listWrapper: imageLoaded ? styles.carouselGradient : undefined,
                            item: classnames(styles.carouselItem, {
                                [styles.browseMoreTile]: hasMorePhotos,
                            }),
                        }}
                        dataTn="sb-interiors-photo-carousel"
                        totalItems={totalItems}
                        isInfinite={false}
                        slideCarouselItems
                        itemsPerPage={ITEMS_PER_PAGE}
                        step={ITEMS_PER_PAGE}
                        onIndexChange={({ index }: { index: number }) => onPhotoIndexChange(index)}
                        renderItem={({ index }: { index: number; isCurrentItem: boolean }) => {
                            const currentPhoto = photos[index];
                            const { photoLikeId } =
                                photoLikeDataMap.get(currentPhoto?.serviceId || '') || {};
                            return (
                                <>
                                    {index === photoCount && hasMorePhotos ? (
                                        <SbSharedInteriorsBrowseMore
                                            interiorPhotos={interiorPhotos}
                                        />
                                    ) : (
                                        <ImageLink
                                            index={index}
                                            onClick={() => onPhotoClickCallback(index)}
                                        >
                                            <SbRespInteriorsLazyLoadImage
                                                imgClass={styles.carouselImg}
                                                lazyLoadClass={styles.carouselLazyLoad}
                                                photo={currentPhoto}
                                                onImageLoaded={() => setImageLoaded(true)}
                                            />
                                            {imageLoaded && (
                                                <div
                                                    className={styles.overlay}
                                                    onClick={onViewInteriorClick}
                                                    role="button"
                                                    tabIndex={0}
                                                    onKeyDown={event => {
                                                        if (event.key === 'Enter') {
                                                            onViewInteriorClick();
                                                        }
                                                    }}
                                                >
                                                    {isClient && !isRefetching && (
                                                        <SbSharedInteriorsHeartWrapper
                                                            onPhotoLikeAdded={onPhotoLikeAdded}
                                                            photoLikeId={photoLikeId}
                                                            photo={currentPhoto}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </ImageLink>
                                    )}
                                </>
                            );
                        }}
                    />
                )}
            </div>
            <div className={styles.relatedSearchContainer}>
                <SbSharedInteriorsPhotoRelatedSearchLinks
                    isPillBackgroundWhite
                    interiorPhotos={interiorPhotos}
                />
            </div>
        </div>
    );
};

export const SbRespInteriorsPhotoCarousel = createFragmentContainer(
    SbRespInteriorsPhotoCarouselComponent,
    {
        itemSearch: graphql`
            fragment SbRespInteriorsPhotoCarousel_itemSearch on ItemSearchQueryConnection {
                ...SbSharedInteriorsHeader_itemSearch
            }
        `,
        interiorPhotos: graphql`
            fragment SbRespInteriorsPhotoCarousel_interiorPhotos on SearchBrowseInteriorPhotosModule {
                ...SbSharedInteriorsPhotoRelatedSearchLinks_interiorPhotos
                ...SbSharedInteriorsBrowseMore_interiorPhotos
                totalResults
            }
        `,
        photos: graphql`
            fragment SbRespInteriorsPhotoCarousel_photos on PhotosSearchBrowsePhoto
            @relay(plural: true) {
                id
                serviceId
                path
                name
                ...usePhotosLikeDataRefetch_photos
                ...SbSharedInteriorsHeartWrapper_photo
            }
        `,
        user: graphql`
            fragment SbRespInteriorsPhotoCarousel_user on User {
                serviceId
            }
        `,
    }
);
