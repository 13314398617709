/**
 * @generated SignedSource<<25238e0c1031c0899d5b80c525ae7416>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespInteriorsPhotoGallery_user$data = {
  readonly serviceId: string | null;
  readonly " $fragmentType": "SbRespInteriorsPhotoGallery_user";
};
export type SbRespInteriorsPhotoGallery_user$key = {
  readonly " $data"?: SbRespInteriorsPhotoGallery_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespInteriorsPhotoGallery_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespInteriorsPhotoGallery_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "f25edf5adbdc52334eab8302f9a03b96";

export default node;
