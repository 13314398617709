import { type FC, useEffect } from 'react';
import { graphql, useFragment } from 'react-relay';
import { trackPromoImpressions, trackPromoClick } from '../helpers/sbSharedFeaturedTracking';

// components
import { SeoLink } from 'dibs-seo/exports/SeoLink';
import { defineMessages, FormattedMessage, FormattedNumber, useIntl } from 'dibs-react-intl';
import { SbSharedL1FeaturedTile } from '../SbSharedL1FeaturedTile/SbSharedL1FeaturedTile';

// types
import { type SbSharedFeaturedDeal_itemSearch$key as ItemSearch } from './__generated__/SbSharedFeaturedDeal_itemSearch.graphql';

type Vertical = 'furniture' | 'art' | 'jewelry' | 'fashion';

type Props = {
    currency: string;
    itemSearch: ItemSearch;
    position?: number;
};

const verticalMessages = defineMessages<Vertical>({
    furniture: {
        id: 'SbSharedFeaturedDeal.price.furniture',
        defaultMessage: 'Furniture under {price}',
    },
    art: {
        id: 'SbSharedFeaturedDeal.price.art',
        defaultMessage: 'Art under {price}',
    },
    jewelry: {
        id: 'SbSharedFeaturedDeal.price.jewelry',
        defaultMessage: 'Jewelry under {price}',
    },
    fashion: {
        id: 'SbSharedFeaturedDeal.price.fashion',
        defaultMessage: 'Fashion under {price}',
    },
});

const itemSearchFragment = graphql`
    fragment SbSharedFeaturedDeal_itemSearch on ItemSearchQueryConnection {
        featuredDeal {
            imageUrl
            vertical
            convertedAmountList {
                amount
                currency
                linkData {
                    ...SeoLink_linkData
                }
            }
        }
    }
`;

const CREATIVE_LABEL = 'price bucket';

export const SbSharedFeaturedDeal: FC<Props> = ({
    itemSearch: itemSearchRefRef,
    currency,
    position,
}) => {
    const intl = useIntl();
    const itemSearch = useFragment(itemSearchFragment, itemSearchRefRef);
    const { featuredDeal } = itemSearch || {};
    const { imageUrl, convertedAmountList = null, vertical } = featuredDeal || {};
    const { linkData, amount } =
        convertedAmountList?.find(convertedAmount => convertedAmount?.currency === currency) || {};

    useEffect(() => {
        if (amount && vertical) {
            trackPromoImpressions({
                promotions: [CREATIVE_LABEL],
                position,
            });
        }
    }, [amount, vertical, position]);

    if (!amount || !vertical || !linkData) {
        return null;
    }

    const heading = (
        <FormattedMessage id="SbSharedFeaturedDeal.heading" defaultMessage="Shop Now" />
    );

    const subHeading = intl.formatMessage(verticalMessages[vertical as Vertical], {
        price: (
            <FormattedNumber
                value={amount}
                style="currency"
                currency={currency}
                minimumFractionDigits={0}
                key="SbSharedFeaturedDeal-subHeading-price"
            />
        ),
    });

    return (
        <SbSharedL1FeaturedTile mainImage={imageUrl} heading={heading} subHeading={subHeading}>
            {({ className, children }) => (
                <SeoLink
                    className={className}
                    linkData={linkData}
                    onClick={() => trackPromoClick({ position, promotion: CREATIVE_LABEL })}
                >
                    {children}
                </SeoLink>
            )}
        </SbSharedL1FeaturedTile>
    );
};
