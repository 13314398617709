import { type FC, useRef } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';
import { SharedRelatedItems } from '../../shared/SharedRelatedItems/SharedRelatedItems';
import { VisibilityTracker } from 'dibs-visibility-tracker/exports/VisibilityTracker';
import { FormattedMessage } from 'dibs-react-intl';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';
import { trackEcommerce, eventNameConstants } from 'dibs-tracking';
import classnames from 'classnames';
import styles from './SbSharedRelatedSearches.scss';

import { type SbSharedRelatedSearches_itemSearch$data } from './__generated__/SbSharedRelatedSearches_itemSearch.graphql';

type Props = {
    itemSearch: SbSharedRelatedSearches_itemSearch$data;
    showTitle?: boolean;
};

const SbSharedRelatedSearchesComponent: FC<Props> = ({ itemSearch, showTitle }) => {
    const { relatedSearches } = itemSearch;
    const { isMobile } = useServerVarsContext();
    const elementRef = useRef(null);
    const wrapperClass = classnames(styles.wrapper, {
        [styles.bottomWrapper]: showTitle,
    });
    const isBottom = !!showTitle;

    if (!relatedSearches?.length) {
        return null;
    }

    const promotions = relatedSearches
        .map((item, index) => {
            const id = item?.anchorText;
            const url = item?.url;
            if (id && url) {
                return {
                    id,
                    position: index + 1,
                    name: `Related Search - ${isBottom ? 'Bottom' : 'Top'}`,
                    creative: url,
                };
            }
            return null;
        })
        .filter(filterFalsy);

    return (
        <div className={wrapperClass} ref={elementRef}>
            <VisibilityTracker
                onVisibilityChange={({ isVisible }) => {
                    if (isVisible && promotions?.length) {
                        trackEcommerce({
                            type: 'promotionImpression',
                            eventName: eventNameConstants.EVENT_VIEW_PROMOTION,
                            promotions,
                        });
                    }
                }}
                elementRef={elementRef}
            />

            {showTitle && (
                <div className={styles.relatedSearchesHeader}>
                    <FormattedMessage
                        id="SbSharedRelatedSearches.relatedSearchesHeader"
                        defaultMessage="Related Searches"
                    />
                </div>
            )}
            <SharedRelatedItems
                wrapperClassName={isBottom ? null : styles.itemsWrapper}
                relatedItems={relatedSearches}
                isFullWidth={isMobile}
                dataTn="related-search-link-item"
                isLinkable={false}
                onClick={index =>
                    trackEcommerce({
                        type: 'promoClick',
                        eventName: eventNameConstants.EVENT_SELECT_PROMOTION,
                        promotions: [promotions[index]],
                    })
                }
            />
        </div>
    );
};

export const SbSharedRelatedSearches = createFragmentContainer(SbSharedRelatedSearchesComponent, {
    itemSearch: graphql`
        fragment SbSharedRelatedSearches_itemSearch on ItemSearchQueryConnection {
            relatedSearches {
                anchorText
                url
            }
        }
    `,
});
