import { Suspense, type FC, useState, useRef } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';
import VisibilityTracker from 'dibs-visibility-tracker/exports/VisibilityTracker';
import { Button } from 'dibs-elements/exports/Button';
import { SbRespInteriorsPhotoGallery } from './SbRespInteriorsPhotoGallery';
import { SbRespInteriorsPhotoCarousel } from './SbRespInteriorsPhotoCarousel';
import { SbSharedInteriorsArticles } from '../SbSharedInteriorsModule/SbSharedInteriorsArticles';
import { SbSharedInteriorsPhotoModalRendererLazy } from '../SbSharedInteriorsModule/SbSharedInteriorsPhotoModal/SbSharedInteriorsPhotoModalRendererLazy';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';
import {
    trackModuleViewed,
    trackPhotoClicked,
    trackViewInteriorCTAClicked,
    trackModuleScroll,
    trackPhotoSave,
} from '../SbSharedInteriorsModule/interiorsModuleHelpers';
import { INITIAL_VISIBLE_INTERIOR_ARTICLES } from '../../constants/sbConstants';
import { viewMore, viewLess } from '../sbMessages';

import styles from './SbRespInteriorsModule.scss';

import { type SbRespInteriorsModule_editorial$data } from './__generated__/SbRespInteriorsModule_editorial.graphql';
import { type SbRespInteriorsModule_itemSearch$data } from './__generated__/SbRespInteriorsModule_itemSearch.graphql';
import { type SbRespInteriorsModule_user$data } from './__generated__/SbRespInteriorsModule_user.graphql';

type Props = {
    editorial: SbRespInteriorsModule_editorial$data | null | undefined;
    itemSearch: SbRespInteriorsModule_itemSearch$data;
    user: SbRespInteriorsModule_user$data;
    isClient: boolean;
};

const SbRespInteriorsModuleComponent: FC<Props> = ({ editorial, itemSearch, user, isClient }) => {
    const totalArticlesCount = editorial?.totalResults || 0;
    const hasArticles = totalArticlesCount > 0;
    const interiorPhotos = itemSearch.interiorPhotos;
    const photos = (interiorPhotos?.photos || []).filter(filterNulls);
    const meta = itemSearch.meta;
    const displayHeader = meta?.attributeDisplayName || meta?.header;
    const showMainHeader = hasArticles && displayHeader;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isModuleVisible, setIsModuleVisible] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const elementRef = useRef(null);

    const handleVisibilityChange = ({ isVisible }: { isVisible: boolean }): void => {
        if (isVisible) {
            setIsModuleVisible(true);
            trackModuleViewed({ interiorPhotos });
        }
    };

    const handlePhotoClick = (): void => {
        setIsModalOpen(true);
        trackPhotoClicked({ interiorPhotos });
    };

    const handleViewInteriorClick = (): void => {
        setIsModalOpen(true);
        trackViewInteriorCTAClicked({ interiorPhotos });
    };

    const onPhotoLikeAdded = (): void => {
        trackPhotoSave({ interiorPhotos });
    };

    if (!interiorPhotos && !editorial) {
        return null;
    }

    return (
        <>
            {showMainHeader && (
                <HeadingLevel>
                    {Heading => (
                        <Heading className={styles.header}>
                            <FormattedMessage
                                id="abf.SbRespInteriorsModule.header"
                                defaultMessage="More {name} Inspiration"
                                values={{
                                    name: displayHeader,
                                }}
                            />
                        </Heading>
                    )}
                </HeadingLevel>
            )}
            <VisibilityTracker
                onVisibilityChange={handleVisibilityChange}
                elementRef={elementRef}
            />
            <div className={styles.wrapper} ref={elementRef}>
                {hasArticles ? (
                    <>
                        <div className={styles.content}>
                            <SbRespInteriorsPhotoGallery
                                interiorPhotos={interiorPhotos}
                                itemSearch={itemSearch}
                                onPhotoClick={handlePhotoClick}
                                photoIndex={photoIndex}
                                onPhotoIndexChange={nextIndex => {
                                    setPhotoIndex(nextIndex);
                                    trackModuleScroll({ interiorPhotos });
                                }}
                                isClient={isClient}
                                onPhotoLikeAdded={onPhotoLikeAdded}
                                user={user}
                                isModuleVisible={isModuleVisible}
                            />
                            <SbSharedInteriorsArticles
                                imgSizes="192px"
                                editorial={editorial}
                                isExpanded={isExpanded}
                            />
                        </div>
                        {totalArticlesCount > INITIAL_VISIBLE_INTERIOR_ARTICLES && (
                            <div className={styles.viewMoreButtonWrapper}>
                                <Button
                                    type="transparent"
                                    size="medium"
                                    dataTn="interior-articles-view-more"
                                    className={styles.viewMoreButton}
                                    onClick={() => setIsExpanded(!isExpanded)}
                                >
                                    {isExpanded ? viewLess : viewMore}
                                </Button>
                            </div>
                        )}
                    </>
                ) : (
                    <div className={styles.content}>
                        <SbRespInteriorsPhotoCarousel
                            interiorPhotos={interiorPhotos}
                            photos={photos}
                            itemSearch={itemSearch}
                            onViewInteriorClick={handleViewInteriorClick}
                            onPhotoClick={handlePhotoClick}
                            onPhotoIndexChange={setPhotoIndex}
                            isClient={isClient}
                            onPhotoLikeAdded={onPhotoLikeAdded}
                            user={user}
                            isModuleVisible={isModuleVisible}
                        />
                    </div>
                )}
            </div>
            {isModalOpen && (
                <Suspense fallback="">
                    <SbSharedInteriorsPhotoModalRendererLazy
                        photoIndex={photoIndex}
                        interiorPhotos={interiorPhotos}
                        onClose={() => setIsModalOpen(false)}
                        user={user}
                    />
                </Suspense>
            )}
        </>
    );
};

export const SbRespInteriorsModule = createFragmentContainer(SbRespInteriorsModuleComponent, {
    itemSearch: graphql`
        fragment SbRespInteriorsModule_itemSearch on ItemSearchQueryConnection {
            ...SbRespInteriorsPhotoCarousel_itemSearch
            ...SbRespInteriorsPhotoGallery_itemSearch
            meta {
                attributeDisplayName
                header
            }
            interiorPhotos(template: "tradeProjectPhoto.search", first: $interiorPhotosCount) {
                ...SbSharedInteriorsPhotoModalRenderer_interiorPhotos
                ...SbRespInteriorsPhotoGallery_interiorPhotos
                ...SbRespInteriorsPhotoCarousel_interiorPhotos
                ...interiorsModuleHelpers_interiorPhotos
                photos {
                    ...SbRespInteriorsPhotoCarousel_photos
                }
            }
        }
    `,
    editorial: graphql`
        fragment SbRespInteriorsModule_editorial on WordpressArticlesType {
            ...SbSharedInteriorsArticles_editorial
            totalResults
        }
    `,
    user: graphql`
        fragment SbRespInteriorsModule_user on User {
            ...SbSharedInteriorsPhotoModalRenderer_user
            ...SbRespInteriorsPhotoGallery_user
            ...SbRespInteriorsPhotoCarousel_user
        }
    `,
});
