import { type FC } from 'react';
import { graphql, useFragment } from 'react-relay';

// components
import { InViewport } from 'dibs-in-viewport/exports/InViewport';
import { Carousel } from 'dibs-carousel';
import { SharedCarouselDot } from '../../shared/SharedCarouselDot/SharedCarouselDot';
import { SbSharedL1ModuleTile } from '../SbSharedL1ModuleTile/SbSharedL1ModuleTile';

// types
import { type SbSharedL1ModuleBanner_l1Module$key } from './__generated__/SbSharedL1ModuleBanner_l1Module.graphql';

//styles
import styles from './SbSharedL1ModuleBanner.scss';

type Props = {
    l1Module: SbSharedL1ModuleBanner_l1Module$key | null | undefined;
};

const l1ModuleFragment = graphql`
    fragment SbSharedL1ModuleBanner_l1Module on L1Module {
        banners {
            ...SbSharedL1ModuleTile_l1ModuleItemTile
        }
    }
`;

export const SbSharedL1ModuleBanner: FC<Props> = ({ l1Module: l1ModuleRef }) => {
    const l1Module = useFragment(l1ModuleFragment, l1ModuleRef);
    const { banners } = l1Module || {};

    const bannersLength = banners?.length || 0;

    if (!bannersLength) {
        return null;
    }

    return (
        <InViewport>
            {({ inViewport }) => (
                <Carousel
                    isAutoRun={inViewport && bannersLength > 1}
                    classNames={{ wrapper: styles.carousel }}
                    dataTn="l1-banner-carousel"
                    showDots={bannersLength > 1}
                    hideArrows
                    totalItems={bannersLength}
                    itemsPerPage={1}
                    step={1}
                    renderItem={({ index }: { index: number }) =>
                        banners?.[index] ? (
                            <SbSharedL1ModuleTile
                                l1ModuleItemTile={banners[index]}
                                creative="Middle Tile"
                                position={index + 1}
                                isBannerTile
                            />
                        ) : null
                    }
                    renderDot={({ isCurrentDot }: { isCurrentDot?: boolean } = {}) => (
                        <SharedCarouselDot isActive={!!isCurrentDot} />
                    )}
                />
            )}
        </InViewport>
    );
};
