/**
 * @generated SignedSource<<263d252ce7dfa803b168d26fbd7e2673>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespInteriorsPhotoCarousel_photos$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string | null;
  readonly path: string | null;
  readonly serviceId: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedInteriorsHeartWrapper_photo" | "usePhotosLikeDataRefetch_photos">;
  readonly " $fragmentType": "SbRespInteriorsPhotoCarousel_photos";
}>;
export type SbRespInteriorsPhotoCarousel_photos$key = ReadonlyArray<{
  readonly " $data"?: SbRespInteriorsPhotoCarousel_photos$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespInteriorsPhotoCarousel_photos">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SbRespInteriorsPhotoCarousel_photos",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePhotosLikeDataRefetch_photos"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedInteriorsHeartWrapper_photo"
    }
  ],
  "type": "PhotosSearchBrowsePhoto",
  "abstractKey": null
};

(node as any).hash = "0898ed565145489f6e73aa06d2775ce7";

export default node;
