/**
 * @generated SignedSource<<55a8ea3ed8aacd05a75a4c6208f1b1ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespComponents_shippingFilters$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedPersonalizedFilterToast_shippingFilters">;
  readonly " $fragmentType": "SbRespComponents_shippingFilters";
}>;
export type SbRespComponents_shippingFilters$key = ReadonlyArray<{
  readonly " $data"?: SbRespComponents_shippingFilters$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespComponents_shippingFilters">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SbRespComponents_shippingFilters",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedPersonalizedFilterToast_shippingFilters"
    }
  ],
  "type": "ShippingFilterType",
  "abstractKey": null
};

(node as any).hash = "efe06964b9b07555da0ee972e23cecaa";

export default node;
