import {
    trackEvent,
    eventNameConstants,
    type stepInteractionConstants,
    interactionTypeConstants,
} from 'dibs-tracking';

type TrackDesignFeaturePayload = {
    action: string;
    label: 'profile' | 'project' | 'photo' | 'directory' | 'photos' | 'browse';
};

export const trackDesignFeature = ({ action, label }: TrackDesignFeaturePayload): void => {
    trackEvent({
        action,
        label,
        category: 'design features',
    });
};

type TrackViewProfileClickPayload = {
    action: 'photo' | 'trade directory';
};

export const trackViewProfileClick = ({ action }: TrackViewProfileClickPayload): void => {
    trackEvent({
        action,
        category: 'design features',
        label: 'view profile clicked',
    });
};

type TrackContactDesignerPayload = {
    label: 'profile' | 'directory';
};

export const trackContactDesignerClick = ({ label }: TrackContactDesignerPayload): void => {
    trackDesignFeature({
        action: 'contact designer clicked',
        label,
    });
};

export const trackContactDesignerSubmit = ({ label }: TrackContactDesignerPayload): void => {
    trackDesignFeature({
        action: 'contact designer submitted',
        label,
    });
};

type TrackShareProfilePayload = {
    label: 'profile' | 'project' | 'photo';
    shareType: 'email' | 'pinterest' | 'facebook' | 'copy link';
};

export const trackShareProfile = ({ label, shareType }: TrackShareProfilePayload): void => {
    trackDesignFeature({
        action: `share profile - ${shareType}`,
        label,
    });
};

type TrackLoginSavePhotoFeaturePayload = {
    action:
        | typeof stepInteractionConstants.STEP_INTERACTION_COMPLETE
        | typeof stepInteractionConstants.STEP_INTERACTION_ENTRY;
};

export const trackLoginSavePhotoFeature = ({ action }: TrackLoginSavePhotoFeaturePayload): void => {
    trackEvent({
        action: `login ${action}`,
        label: 'photo save',
        category: 'login',
        eventName: eventNameConstants.EVENT_LOGIN,
        step_interaction_name: action,
        interaction_type: interactionTypeConstants.INTERACTION_TYPE_USER_LOGIN,
    });
};

type TrackPhotoActionPayload = {
    label: TrackDesignFeaturePayload['label'];
};

export const trackSavePhoto = ({ label }: TrackPhotoActionPayload): void => {
    trackDesignFeature({
        action: 'photo save',
        label,
    });
};

export const trackPhotoClick = ({ label }: TrackPhotoActionPayload): void => {
    trackDesignFeature({
        action: 'photo click',
        label,
    });
};

export const trackRelatedSearchClick = ({ label }: TrackPhotoActionPayload): void => {
    trackDesignFeature({
        action: 'related search click',
        label,
    });
};
