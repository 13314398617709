import { type FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import EditorialModuleArticles from 'dibs-editorial-module/exports/EditorialModuleArticles';
import { SbSharedWordpressArticlesContainer } from './SbSharedWordpressArticlesContainer';

import { type SbSharedWordpressArticlesWrapper_editorial$data } from './__generated__/SbSharedWordpressArticlesWrapper_editorial.graphql';
import { type SbSharedWordpressArticlesWrapper_viewer$data } from './__generated__/SbSharedWordpressArticlesWrapper_viewer.graphql';

type Props = {
    editorial: SbSharedWordpressArticlesWrapper_editorial$data | null | undefined;
    viewer: SbSharedWordpressArticlesWrapper_viewer$data;
};

const SbSharedWordpressArticlesWrapperComponent: FC<Props> = ({ editorial, viewer }) => {
    const showEditorial = (editorial?.totalResults || 0) > 0;
    if (viewer?.isWordpressArticlesEnabled && showEditorial) {
        return (
            <SbSharedWordpressArticlesContainer>
                <EditorialModuleArticles articles={editorial?.articles || []} />
            </SbSharedWordpressArticlesContainer>
        );
    }
    return null;
};

export const SbSharedWordpressArticlesWrapper = createFragmentContainer(
    SbSharedWordpressArticlesWrapperComponent,
    {
        viewer: graphql`
            fragment SbSharedWordpressArticlesWrapper_viewer on Viewer {
                isWordpressArticlesEnabled: isEnabledByLocale(featureName: "WP_EDITORIAL")
            }
        `,
        editorial: graphql`
            fragment SbSharedWordpressArticlesWrapper_editorial on WordpressArticlesType {
                articles {
                    ...EditorialModuleArticles_articles
                }
                totalResults
            }
        `,
    }
);
