/**
 * @generated SignedSource<<8b71c125b34f62b25a6f580e62a68589>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedBillboardAd_itemSearch$data = {
  readonly displayUriRef: string | null;
  readonly " $fragmentType": "SbSharedBillboardAd_itemSearch";
};
export type SbSharedBillboardAd_itemSearch$key = {
  readonly " $data"?: SbSharedBillboardAd_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedBillboardAd_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedBillboardAd_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayUriRef",
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "cf87c77b8210f4aef685eb02e7c9deca";

export default node;
