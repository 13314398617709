/**
 * @generated SignedSource<<e62cc7686ab89edb802ee51084e202cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedInteriorsHeartWrapper_photo$data = {
  readonly id: string;
  readonly photoLike: {
    readonly totalPhotoLikes: number | null;
  } | null;
  readonly serviceId: string | null;
  readonly " $fragmentType": "SbSharedInteriorsHeartWrapper_photo";
};
export type SbSharedInteriorsHeartWrapper_photo$key = {
  readonly " $data"?: SbSharedInteriorsHeartWrapper_photo$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedInteriorsHeartWrapper_photo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedInteriorsHeartWrapper_photo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PhotoLike",
      "kind": "LinkedField",
      "name": "photoLike",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalPhotoLikes",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PhotosSearchBrowsePhoto",
  "abstractKey": null
};

(node as any).hash = "9d2420948b12988a7e5cb618262b8d4a";

export default node;
