/**
 * @generated SignedSource<<4dddea3a0aa19d315ecc16510d70dc0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedSearchSuggestions_itemSearch$data = {
  readonly pageType: string | null;
  readonly searchCorrections: {
    readonly suggestions: ReadonlyArray<{
      readonly name: string | null;
      readonly uri: string | null;
    } | null> | null;
  } | null;
  readonly searchTerm: string | null;
  readonly " $fragmentType": "SbSharedSearchSuggestions_itemSearch";
};
export type SbSharedSearchSuggestions_itemSearch$key = {
  readonly " $data"?: SbSharedSearchSuggestions_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedSearchSuggestions_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedSearchSuggestions_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pageType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "searchTerm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchCorrectionsType",
      "kind": "LinkedField",
      "name": "searchCorrections",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchCorrectionSuggestionsValues",
          "kind": "LinkedField",
          "name": "suggestions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "uri",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "7eddf062e64d3a1365613f8475f6ac66";

export default node;
