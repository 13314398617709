/**
 * @generated SignedSource<<299ba69fa629dc4f0a509d4d95e4267a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespTradeFreeShippingPromoTile_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedPriceTile_itemSearch">;
  readonly " $fragmentType": "SbRespTradeFreeShippingPromoTile_itemSearch";
};
export type SbRespTradeFreeShippingPromoTile_itemSearch$key = {
  readonly " $data"?: SbRespTradeFreeShippingPromoTile_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespTradeFreeShippingPromoTile_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespTradeFreeShippingPromoTile_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedPriceTile_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "b18c6827312034d7f6d6894289270497";

export default node;
