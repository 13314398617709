import { type FC, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import PhotoArrows from 'dibs-interiors-photos/exports/PhotoArrows';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';
import { usePhotosLikeDataRefetch } from 'dibs-interiors-photos/exports/usePhotosLikeDataRefetch';
import { SbSharedInteriorsHeader } from '../SbSharedInteriorsModule/SbSharedInteriorsHeader';
import { SbSharedInteriorsBrowseMore } from '../SbSharedInteriorsModule/SbSharedInteriorsBrowseMore';
import { SbRespInteriorsLazyLoadImage } from './SbRespInteriorsLazyLoadImage';
import { getPhotoIndex } from '../SbSharedInteriorsModule/interiorsModuleHelpers';
import SbSharedInteriorsHeartWrapper from '../SbSharedInteriorsModule/SbSharedInteriorsHeartWrapper/SbSharedInteriorsHeartWrapper';
import SbSharedInteriorsPhotoRelatedSearchLinks from '../SbSharedInteriorsModule/SbSharedInteriorsPhotoRelatedSearchLinks';

import { type SbRespInteriorsPhotoGallery_interiorPhotos$data } from './__generated__/SbRespInteriorsPhotoGallery_interiorPhotos.graphql';
import { type SbRespInteriorsPhotoGallery_itemSearch$data } from './__generated__/SbRespInteriorsPhotoGallery_itemSearch.graphql';
import { type SbRespInteriorsPhotoGallery_user$data } from './__generated__/SbRespInteriorsPhotoGallery_user.graphql';

import styles from './SbRespInteriorsPhotoGallery.scss';

type Props = {
    interiorPhotos: SbRespInteriorsPhotoGallery_interiorPhotos$data | null | undefined;
    itemSearch: SbRespInteriorsPhotoGallery_itemSearch$data;
    photoIndex: number;
    onPhotoIndexChange: (newIndex: number) => void;
    onPhotoClick: () => void;
    isClient: boolean;
    onPhotoLikeAdded: () => void;
    user: SbRespInteriorsPhotoGallery_user$data;
    isModuleVisible: boolean;
};

const SbRespInteriorsPhotoGalleryComponent: FC<Props> = ({
    interiorPhotos,
    itemSearch,
    photoIndex,
    onPhotoIndexChange,
    onPhotoClick,
    isClient,
    onPhotoLikeAdded,
    user,
    isModuleVisible,
}) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    const photos = interiorPhotos?.photos?.filter(filterNulls) || [];
    // +1 b/c showing browse screen at the end
    const photoCount = photos.length + 1;
    const lastPhotoIndex = photoCount - 1;
    const handlePageChange = (nextIndex: number): void => {
        onPhotoIndexChange(getPhotoIndex(nextIndex, lastPhotoIndex));
    };

    const isLastPage = photoIndex === lastPhotoIndex;
    const photo = photos[photoIndex];
    const { photoLikeDataMap, isRefetching } = usePhotosLikeDataRefetch({
        photosRef: photos,
        shouldFetch: isModuleVisible,
        userId: user?.serviceId || '',
    });
    const { photoLikeId } = photoLikeDataMap.get(photo?.serviceId || '') || {};

    if (photos.length === 0) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.imgWrapper}>
                {isLastPage ? (
                    <div className={styles.browseMoreWrapper}>
                        <SbSharedInteriorsBrowseMore interiorPhotos={interiorPhotos} />
                    </div>
                ) : (
                    <SbRespInteriorsLazyLoadImage
                        imgClass={styles.img}
                        photo={photo}
                        onImageLoaded={() => setImageLoaded(true)}
                    />
                )}
                {photoCount > 0 && imageLoaded && (
                    <div
                        className={styles.overlayWrapper}
                        onClick={onPhotoClick}
                        role="button"
                        tabIndex={0}
                        onKeyDown={event => {
                            if (event.key === 'Enter') {
                                onPhotoClick();
                            }
                        }}
                    >
                        <div className={styles.overlay}>
                            {!isLastPage && !isRefetching && isClient && (
                                <SbSharedInteriorsHeartWrapper
                                    onPhotoLikeAdded={onPhotoLikeAdded}
                                    photoLikeId={photoLikeId}
                                    photo={photo}
                                />
                            )}
                            <PhotoArrows
                                hideNext={isLastPage}
                                hidePrev={photoIndex === 0}
                                onNextClick={event => {
                                    event.stopPropagation();
                                    handlePageChange(photoIndex + 1);
                                }}
                                onPrevClick={event => {
                                    event.stopPropagation();
                                    handlePageChange(photoIndex - 1);
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.headerWrapper}>
                <SbSharedInteriorsHeader itemSearch={itemSearch} />
            </div>
            <div className={styles.relatedSearchContainer}>
                <SbSharedInteriorsPhotoRelatedSearchLinks
                    isPillBackgroundWhite
                    interiorPhotos={interiorPhotos}
                />
            </div>
        </div>
    );
};

export const SbRespInteriorsPhotoGallery = createFragmentContainer(
    SbRespInteriorsPhotoGalleryComponent,
    {
        itemSearch: graphql`
            fragment SbRespInteriorsPhotoGallery_itemSearch on ItemSearchQueryConnection {
                ...SbSharedInteriorsHeader_itemSearch
            }
        `,
        interiorPhotos: graphql`
            fragment SbRespInteriorsPhotoGallery_interiorPhotos on SearchBrowseInteriorPhotosModule {
                ...SbSharedInteriorsBrowseMore_interiorPhotos
                ...interiorsModuleHelpers_interiorPhotos
                ...SbSharedInteriorsPhotoRelatedSearchLinks_interiorPhotos
                photos {
                    id
                    path
                    name
                    url
                    serviceId
                    ...usePhotosLikeDataRefetch_photos
                    ...SbSharedInteriorsHeartWrapper_photo
                }
            }
        `,
        user: graphql`
            fragment SbRespInteriorsPhotoGallery_user on User {
                serviceId
            }
        `,
    }
);
