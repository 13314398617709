/**
 * @generated SignedSource<<165772181003c57aa8267aba75ad37da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespInteriorsModule_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbRespInteriorsPhotoCarousel_user" | "SbRespInteriorsPhotoGallery_user" | "SbSharedInteriorsPhotoModalRenderer_user">;
  readonly " $fragmentType": "SbRespInteriorsModule_user";
};
export type SbRespInteriorsModule_user$key = {
  readonly " $data"?: SbRespInteriorsModule_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespInteriorsModule_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespInteriorsModule_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedInteriorsPhotoModalRenderer_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespInteriorsPhotoGallery_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespInteriorsPhotoCarousel_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "a86774c2376405b5f1ee164b90c538f7";

export default node;
