/**
 * @generated SignedSource<<5cab7b50c4666728962f98e60ce27eb9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type usePhotosLikeDataRefetch_photos$data = ReadonlyArray<{
  readonly serviceId: string | null;
  readonly " $fragmentType": "usePhotosLikeDataRefetch_photos";
}>;
export type usePhotosLikeDataRefetch_photos$key = ReadonlyArray<{
  readonly " $data"?: usePhotosLikeDataRefetch_photos$data;
  readonly " $fragmentSpreads": FragmentRefs<"usePhotosLikeDataRefetch_photos">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "usePhotosLikeDataRefetch_photos",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    }
  ],
  "type": "PhotosSearchBrowsePhoto",
  "abstractKey": null
};

(node as any).hash = "38a6025efc913f410e1d25db6ab52dc3";

export default node;
