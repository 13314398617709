/**
 * @generated SignedSource<<77f27d5b1a550447c1f02941fa9fb325>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedWordpressArticlesWrapper_viewer$data = {
  readonly isWordpressArticlesEnabled: boolean | null;
  readonly " $fragmentType": "SbSharedWordpressArticlesWrapper_viewer";
};
export type SbSharedWordpressArticlesWrapper_viewer$key = {
  readonly " $data"?: SbSharedWordpressArticlesWrapper_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedWordpressArticlesWrapper_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedWordpressArticlesWrapper_viewer",
  "selections": [
    {
      "alias": "isWordpressArticlesEnabled",
      "args": [
        {
          "kind": "Literal",
          "name": "featureName",
          "value": "WP_EDITORIAL"
        }
      ],
      "kind": "ScalarField",
      "name": "isEnabledByLocale",
      "storageKey": "isEnabledByLocale(featureName:\"WP_EDITORIAL\")"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "d403a43da19ace499b53366d33689759";

export default node;
