import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import PackageIcon from 'dibs-icons/exports/legacy/ShippingPackage';

import { SbSharedPriceTile } from '../SbSharedPriceTile/SbSharedPriceTile';
import { updateUriRef } from '../../actions/filterActions';
import { QP_PERSONALIZED_FILTER_VALUE_SHIPPING_FREE } from '../../constants/queryParamConstants';
import { FILTER_FREE_SHIPPING } from '../SbSharedRefineMenu/sbSharedRefineMenuHelpers';

export function SbRespTradeFreeShippingPromoTileComponent(props) {
    const { applyFreeShippingFilter, position, itemSearch, viewer } = props;

    return (
        <SbSharedPriceTile
            dataTn="priceTile-free-shipping"
            displayName={
                <FormattedMessage
                    id="sb.SbRespTradeFreeShippingPromoTile.title"
                    defaultMessage="Free Shipping"
                />
            }
            hoverMessage={
                <FormattedMessage
                    id="sb.SbRespTradeFreeShippingPromoTile.shopFreeItems"
                    defaultMessage="shop items that ship free to you"
                />
            }
            IconComponent={PackageIcon}
            image="https://a.1stdibscdn.com/dist/adhoc/trade/free-shipping/free-shipping-tile.jpg?height=180"
            isSbUrl={false}
            onClick={() => {
                const filters = get(viewer, 'shippingFilters') || [];
                const freeShippingFilter = filters.find(
                    f => get(f, 'name') === QP_PERSONALIZED_FILTER_VALUE_SHIPPING_FREE
                );
                const freeShippingRegions = get(freeShippingFilter, 'values') || [];
                if (freeShippingRegions.length > 0) {
                    applyFreeShippingFilter(freeShippingRegions);
                }
            }}
            promoLabel="Free Shipping"
            position={position}
            itemSearch={itemSearch}
        />
    );
}

SbRespTradeFreeShippingPromoTileComponent.propTypes = {
    applyFreeShippingFilter: PropTypes.func.isRequired,
    currency: PropTypes.string,
    position: PropTypes.number,
    itemSearch: PropTypes.object.isRequired,
    viewer: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
    return {
        applyFreeShippingFilter(filterValues) {
            const filterValue = filterValues?.[0] || {};
            dispatch(updateUriRef({ FILTER_FREE_SHIPPING, filterValue }));
        },
    };
}

export const SbRespTradeFreeShippingPromoTile = createFragmentContainer(
    connect(null, mapDispatchToProps)(SbRespTradeFreeShippingPromoTileComponent),
    {
        itemSearch: graphql`
            fragment SbRespTradeFreeShippingPromoTile_itemSearch on ItemSearchQueryConnection {
                ...SbSharedPriceTile_itemSearch
            }
        `,
        viewer: graphql`
            fragment SbRespTradeFreeShippingPromoTile_viewer on Viewer {
                shippingFilters(
                    uriRef: $uriRef
                    zipCode: $userZipCode
                    countryCode: $userCountryCode
                ) @include(if: $isClient) {
                    name
                    values {
                        urlLabel
                        displayName
                        linkReference
                    }
                }
            }
        `,
    }
);
