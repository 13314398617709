import { type FC, type MouseEventHandler } from 'react';
import { Link } from 'dibs-elements/exports/Link';
import ArrowLeft from 'dibs-icons/exports/legacy/ArrowLeft';
import ArrowRight from 'dibs-icons/exports/legacy/ArrowRight';

import styles from './PhotoArrows.scss';

type ArrowProps = {
    onClick: MouseEventHandler<HTMLDivElement>;
};

const LeftArrow: FC<ArrowProps> = ({ onClick }) => {
    return (
        <Link className={styles.leftArrowBox} onClick={onClick}>
            <ArrowLeft className={styles.arrow} />
        </Link>
    );
};

const RightArrow: FC<ArrowProps> = ({ onClick }) => {
    return (
        <Link className={styles.rightArrowBox} onClick={onClick}>
            <ArrowRight className={styles.arrow} />
        </Link>
    );
};

type Props = {
    hidePrev?: boolean;
    hideNext?: boolean;
    onNextClick: MouseEventHandler<HTMLDivElement>;
    onPrevClick: MouseEventHandler<HTMLDivElement>;
};

const PhotoArrows: FC<Props> = props => {
    const { hidePrev, hideNext, onPrevClick, onNextClick } = props;
    return (
        <>
            {!hidePrev && <LeftArrow onClick={onPrevClick} />}
            {!hideNext && <RightArrow onClick={onNextClick} />}
        </>
    );
};

export default PhotoArrows;
