/**
 * @generated SignedSource<<87d814f9bb3c9f45076eb6082e6cba73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespInteriorsPhotoCarousel_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedInteriorsHeader_itemSearch">;
  readonly " $fragmentType": "SbRespInteriorsPhotoCarousel_itemSearch";
};
export type SbRespInteriorsPhotoCarousel_itemSearch$key = {
  readonly " $data"?: SbRespInteriorsPhotoCarousel_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespInteriorsPhotoCarousel_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespInteriorsPhotoCarousel_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedInteriorsHeader_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "1a9d9ca9cc0322423cbc2d7f1ed8142a";

export default node;
