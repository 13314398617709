/**
 * @generated SignedSource<<f6c29d98d50becf98d1c4c43be484f79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespInteriorsWordpressArticlesWrapper_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbRespInteriorsModule_user">;
  readonly " $fragmentType": "SbRespInteriorsWordpressArticlesWrapper_user";
};
export type SbRespInteriorsWordpressArticlesWrapper_user$key = {
  readonly " $data"?: SbRespInteriorsWordpressArticlesWrapper_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespInteriorsWordpressArticlesWrapper_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespInteriorsWordpressArticlesWrapper_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespInteriorsModule_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "f1951a386bdbc934d02b69538a292d9a";

export default node;
